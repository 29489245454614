import React from "react"

import Layout from "../components/UI/layout"
import Seo from "../components/util/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>It looks like this page does not exist... Sorry!</p>
  </Layout>
)

export default NotFoundPage
